/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import ResultList from 'new-ui/Results/ResultList';
import Button from 'new-ui/Components/Button';
import Action from 'new-ui/Widgets/Action';
import {
  ACTION_TYPES, POPUP_TYPES, ROUTES, PAGES, THEMES, URI,
  // INDICATIONS,
  DEFAULT_INDICATION,
} from 'new-ui/constants';
import knobsIcon from 'new-ui/assets/icons/filter.svg';
import MapIcon from 'new-ui/assets/icons/map2.svg';
import DownloadIcon from 'new-ui/assets/icons/download-pdf.svg';
import GlassIcon from 'new-ui/assets/icons/glass-white.svg';
// import arrowDown from 'new-ui/assets/icons/arrow-circle-black.svg';
// import ActionsIcon from 'new-ui/assets/icons/more-circle.svg';
import ArrowRightIcon from 'new-ui/assets/icons/arrow-circle-right.svg';
import { ReactComponent as SearchIconBlack } from 'new-ui/assets/icons/search-zoom-in-black.svg';
import { ReactComponent as PatientsIcon } from 'new-ui/assets/icons/patients.svg';
import { ReactComponent as UserIcon } from 'new-ui/assets/icons/user2.svg';
import { ReactComponent as LoginIcon } from 'new-ui/assets/icons/login-button.svg';
import { ReactComponent as UserTickIcon } from 'new-ui/assets/icons/user_tick.svg';
import { ReactComponent as SearchIcon } from 'new-ui/assets/icons/search-zoom-in.svg';
import {
  getFilteredResults, getInitialConditionProfileByIndication, getLoggedUser,
  randomString,
} from 'new-ui/functions';
import { useHistory } from 'react-router-dom';
import { gotoQuestionnaire, getUserDataByParams } from 'new-ui/functions/gotoQuestionnaire';
import { isNGSSource } from 'new-ui/NGS';
import { downloadPDF } from 'new-ui/api';
import './Search.css';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
// import Preloader from 'new-ui/Components/Preloader';
import ResultsSkeleton from 'new-ui/Results/ResultList/ResultsSkeleton';
import { openSponseredTrialInfoPopup } from 'new-ui/Components/ItemBox/functions';
import LinkButton from 'new-ui/Components/LinkButton';
import { USER_TYPES, USER_TYPES_VALUES } from 'new-ui/Components/QuestionRenderer/renderers/IntakeAboutYou';
import Preloader from 'new-ui/Components/Preloader';
import { getQstack } from 'new-ui/Components/Intake/functions';
import useForceUpdate from 'use-force-update';
import { getFinanceParams } from 'new-ui/Pages/Finance/functions';
import ProfileButton from 'new-ui/Components/ProfileButton';
import { ReactComponent as StarImg } from '../assets/icons/star.svg';
import UploadIcon from '../Pages/Upload/assets/upload-files.svg';
import AnalyzeIcon from '../assets/icons/analyze.svg';
import SearchParameters, { getEmptyParams, params2form } from './SearchParameters';
import { addUserDocuments } from '../../modules/userDocuments/api';
import { hcpApi } from './api';
import { GA_HCP } from './GA';
// import { CancerFieldsByIndication } from 'new-ui/api/data/cancerTypes';
// import SearchTreatmentList from './SearchTreatmentList';

let hasFilters = false;

const searchParamsApi = {};

export const MODES = {
  TREATMENTS: 'TREATMENTS',
  TRIALS: 'TRIALS',
};

export const MODES_TITLES = {
  [MODES.TREATMENTS]: 'Standard Treatments',
  [MODES.TRIALS]: 'Clinical Trials',
};

export const MODES_TABS = {
  [MODES.TREATMENTS]: 'treatments',
  [MODES.TRIALS]: 'trials',
};

export const getNewPatientEmail = (uid)=>{
  return `patient_of_${uid}_${randomString()}@leal.health`;
};

const resultsListController = {};

export const addCaregiver = ({ onSignup })=>{
  app.setPopup(POPUP_TYPES.ADD_CAREGIVER, {
    theme: 'middle',
    onSignup: async (_user) => {
      app.setShowPreloader(true);
      // app.setPopup(false);
      // history.push(`${ROUTES[PAGES.RESULTS]}?signup=1&rematch=1`);
      app.user = _user;

      if (onSignup) onSignup(_user);

      const documents = app.getDocuments();
      const profileId = _user?.personal?.profile_id;

      if (documents && profileId) {
        addUserDocuments({
          ...documents,
          profileId,
        });
      }

      // todo create finance caregiver user
      const registerFinanceCaregiver = await hcpApi.registerFinanceCaregiver();

      console.log('registerFinanceCaregiver response', registerFinanceCaregiver);

      app.setShowPreloader(false);

      addPatient({
        caregiver: app.user,
        defaultEmail: getNewPatientEmail(app.user.personal.user_wix_id),
        indication: window._searchParams?.condition,
      });
    },

  });
};

export const addPatient = async ({
  caregiver = app?.user,
  name = '',
  email = '',
  emailRequired = false,
  defaultEmail = getNewPatientEmail(app.user.personal.user_wix_id),
  indication = window._searchParams?.condition,
  indicationTitle,
  nameTitle,
  emailTitle,
  buttonTitle,
  doLogin,
  title,
  subtitle,
  onBeforeSignup = async (patient)=>{
    console.log('addPatient', patient);
    if (window._searchParams) {
      window._searchParams.condition = patient.personal.condition;
      const o = await hcpApi.params2user(window._searchParams);
      if (o.success) {
        patient.condition_profile = o.data.condition_profile;
        // const patientStageField = CancerFieldsByIndication[patient.personal.condition].patientStage || 'patient_stage';
        // patient.condition_profile[patientStageField] = window._searchParams.
      }
      patient.info.country = window._searchParams.country;
      if (patient.info?.country?.name) patient.info.country.label = patient.info.country.name;
    }
  },
  onSignup = async (patient, setError)=>{
    try {
      app.setShowPreloader(true);
      app.hcpUser = patient;
      const addPatientResults = await hcpApi.addPatient(patient, app.user);
      if (addPatientResults?.success !== true) {
        return setError('Oops, something went wrong 😅');
      }
      const patientFinanceParams = await getFinanceParams(patient);
      try {
        await hcpApi.createFinancePatient(patientFinanceParams);
      } catch (e) {
        debugger;
      }

      window.location = URI[PAGES.PATIENTS];
    } catch (e) {
      setError('Oops, something is not working! 😅');
    }
    // debugger;
    // app.history.push(ROUTES[PAGES.PATIENTS]);
  },
})=>{
  app.setPopup(POPUP_TYPES.ADD_PATIENT, {
    theme: 'middle',
    caregiver,
    name,
    email,
    defaultEmail,
    onBeforeSignup,
    emailRequired,
    indication,
    onSignup,
    indicationTitle,
    nameTitle,
    emailTitle,
    buttonTitle,
    doLogin,
    title,
    subtitle,
  });
};

const SearchTrials = () => {
  const { t } = useTranslation();
  // const [mode, setMode] = useState(MODES.TRIALS);
  // const [showMobileTabs, setShowMobileTabs] = useState(false);
  // const [showMobileActions, setShowMobileActions] = useState(false);
  const [showPreloader, setShowPreloader] = useState(false);
  const [emptyResults, setEmptyResults] = useState(false);
  const [trials, setTrials] = useState(app.cache.searchTrials || []);
  const [treatments, setTreatments] = useState(app.cache.searchTreatments || []);
  const [filters, setFilters] = useState({});
  const [unfiltered, setUnfiltered] = useState([]);
  const [unfilteredTreatments, setUnfilteredTreatments] = useState([]);
  const [searchParams, setSearchParams] = useState(app.cache.searchParams || null);
  const [expandTrials, setExpandTrials] = useState(false);
  const [expandTreatments, setExpandTreatments] = useState(false);
  // const [patient, setPatient] = useState(null);
  const [params, setParams] = useState(null);
  const update = useForceUpdate();
  window.__update = update;

  const [
    user,
    setUser,
  ] = useState(getLoggedUser());
  const history = useHistory();

  delete app.cache.itemData;

  // useEffect(() => {
  //   if (app.setTab) app.setTab(MODES_TABS[mode]);
  // }, [mode]);

  useEffect(()=>{
    const _urlParams = app.getParams();
    app.intercom.hidden = true;
    const fetch = async ()=>{
      console.log('search fetch');
      await app.getSession();
      if (app.user) {
        app.user = await app.getUser();
        if (_urlParams.get('new')) {
          delete app.hcpUser;
          setUser(getLoggedUser());
          setParams(getEmptyParams(undefined, true));
        } else if (_urlParams.get('indication')) {
          delete app.hcpUser;
          setUser(getLoggedUser());
          const _params = getEmptyParams(_urlParams.get('indication'), true, {
            diseaseType: _urlParams.get('diseaseType') || null,
          });
          setParams(_params);
        } else if (localStorage.getItem('set_patient') && app.user.patients?.length) {
          const SET_PATIENT = localStorage.getItem('set_patient');
          app.hcpUser = app.user.patients.find((p)=>p.info.user_wix_id === SET_PATIENT);
        } else if (!app.hcpUser && app?.user?.patients?.length) {
          app.hcpUser = app.user.patients[0];
        } else {
          setUser(getLoggedUser());
          return setParams(getEmptyParams());
        }
        setUser(getLoggedUser());
      } else {
        let _params = getEmptyParams();
        if (_urlParams.get('indication')) {
          _params = getEmptyParams(_urlParams.get('indication'), true, {
            diseaseType: _urlParams.get('diseaseType') || null,
          });
          // doNew(_urlParams.get('indication'), {
          //   diseaseType: _urlParams.get('diseaseType') || null,
          // }, true);
        }
        setParams(_params);
      }
      if (app.hcpUser) {
        const paramsResult = await hcpApi.user2params(app.hcpUser);
        window.__paramsResult = paramsResult;
        if (!paramsResult.success) console.log('error with paramsResult');
        setParams(paramsResult.data);
      }
    };
    if (_urlParams.get('add')) {
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.SIGNUP_POPUP);
      setParams(getEmptyParams());
      save();
      return;
    }
    fetch();
    // eslint-disable-next-line
  }, []);

  const setHeader = ()=>{
    app.setCustomHeader((
      <>
        <div className="header-buttons">
          {
            user
              ? app.hcpUser ? (
                <Button theme={THEMES.BLACK} title={t('general.new')} action={doNew} customIcon={<SearchIcon />} />
              ) : (
                <Button theme={THEMES.BLACK} title={t('search.save')} action={addPatient} customIcon={<UserTickIcon />} />
              )
              : <Button theme={THEMES.BLACK} title={t('search.save')} action={save} customIcon={<UserTickIcon />} />
          }
          {
            user
              ? (
                <Button
                  theme={THEMES.GREY}
                  title={t('general.patients')}
                  action={()=>{
                    window.location = URI[PAGES.PATIENTS];
                  }}
                  customIcon={<PatientsIcon />}
                />
              )
              : <Button theme={THEMES.GREY} title={t('general.new')} action={doNew} customIcon={<SearchIconBlack />} />
          }

          {
            // <Button theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<UserTickIcon />} />
            !user ? (
              <>
                <Button className="mobile-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<LoginIcon />} />
                <Button className="desktop-only" theme={THEMES.GREY} title={t('general.login')} action={gotoLogin} customIcon={<UserIcon />} />
              </>
            ) : (
              <ProfileButton />
            )
          }
          {/* <Button theme={THEMES.GREY} title={t('search.goto_dashboard')} action={gotoDashboard} customIcon={<CheckIcon />} /> */}
        </div>
      </>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    setHeader();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => () => {
    app.setCustomHeader();
  }, []);

  window.__getUserDataByParams = async () => {
    try {
      const r = await getUserDataByParams(searchParams);
      return r;
    } catch (e) {}
  };

  const gotoLogin = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.LOGIN_CLICKED);
    app.history.push(`${ROUTES[PAGES.LOGIN]}?redirect=/search`);
  };

  const doNew = (indication = null, props, preventAnalytics)=>{
    if (!preventAnalytics) app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NEW_CLICKED);
    delete app.hcpUser;
    localStorage.removeItem('set_patient');
    setHeader();
    const params = getEmptyParams(indication, true, props);
    searchParamsApi.set(params);
    update();
    if (window?._form?.render) window._form.render();
  };

  window.doNew = doNew;

  const createPatientFromParams = async (searchParams = {})=>{
    app.setShowPreloader(true);
    let o = await hcpApi.params2user(searchParams, searchParams.condition);
    const p = await getInitialConditionProfileByIndication(searchParams.condition);

    o = o.data;

    const newUser = {
      condition_profile: p,
      personal: {},
      info: {
        email: getNewPatientEmail(app.user.personal.user_wix_id),
        user_type: USER_TYPES_VALUES[USER_TYPES.PATIENT],
      },
    };

    newUser.condition_profile = Object.assign(newUser.condition_profile, o.condition_profile);
    newUser.info = Object.assign(newUser.info, o.info);
    newUser.personal = Object.assign(newUser.personal, o.personal);
    newUser.qstack = await getQstack(newUser, await app.getQuestions(searchParams.condition || DEFAULT_INDICATION));
    app.setShowPreloader(false);

    console.log('new user for addPatient', newUser);

    return newUser;
  };

  window.__createPatientFromParams = createPatientFromParams;

  const save = async () => {
    const patient = await getUserDataByParams(window._searchParams || {});
    patient.info.user_type = USER_TYPES.PATIENT;

    addCaregiver({
      patient,
      onSignup: ()=>{
        setUser(app.user);
      },
    });

    // app.setPopup(POPUP_TYPES.SIGNUP, {
    //   theme: 'middle',
    //   data,
    //   title: 'Save your results',
    //   onSignup: (_user) => {
    //     app.setPopup(false);
    //     // history.push(`${ROUTES[PAGES.RESULTS]}?signup=1&rematch=1`);
    //     setUser(_user);

    //     const documents = app.getDocuments();
    //     const profileId = _user?.personal?.profile_id;

    //     if (documents && profileId) {
    //       addUserDocuments({
    //         ...documents,
    //         profileId,
    //       });
    //     }

    //     const type = searchParamsApi.indicationLabelsData.filter((a) => a.value === _user.personal.condition)[0];
    //     searchParamsApi.onChange({}, type);

    //     app.setPopup(POPUP_TYPES.ADD_PATIENT, {
    //       theme: 'middle',
    //       onSignup: ()=>{

    //       },
    //     });

    //     // if (app.isIntakeFlow(_user?.personal.condition)) { // todo: remove
    //     //   history.push(`${ROUTES[PAGES.RESULTS]}?signup=1&rematch=1`);
    //     //   return;
    //     // }
    //   },
    // });
  };

  const onSearch = async (trials_ = [], treatments_ = [], params, preventUpdate) => {
    // eslint-disable-next-line no-param-reassign
    console.log('onSearch', trials_, treatments_, params, preventUpdate);
    if (app.isMock()) {
      trials_ = app.trialsMock;
      treatments_ = app.treatmentsMock;
    }
    console.log('search::onSearch', { trials_, treatments_, params });
    trials_.forEach((t_) => { t_.showNCT = true; });
    treatments_.forEach((t_) => { t_.showNCT = false; });
    window._searchTrials = trials_;
    window._searchTreatments = treatments_;
    window._searchParams = params;
    setEmptyResults(false);
    setSearchParams(params);
    setUnfiltered(trials_);
    setUnfilteredTreatments(treatments_);
    app.trials = trials_;
    app.treatments = treatments_;
    app.cache.searchTrials = trials_;
    app.cache.searchTreatments = treatments_;
    app.cache.searchParams = params;
    setTrials(trials_);
    setTreatments(treatments_);
    if (!trials_.length && !treatments_.length) setEmptyResults(true);
    else if (!trials_.length && resultsListController.getTab() === MODES.TRIALS) resultsListController.setTab(MODES.TREATMENTS);
    else if (!treatments_.length && resultsListController.getTab() === MODES.TREATMENTS) resultsListController.setTab(MODES.TRIALS);
  };

  const ELIGIBILITY_DATA = {
    title: t(`action.eligibility_title_${MODES_TABS[resultsListController.getTab ? resultsListController.getTab() : MODES.TRIALS]}`),
    subtitle: t('action.eligibility_subtitle'),
    buttonText: t('action.eligibility_button'),
    action: () => {
      gotoQuestionnaire(searchParams, history);
    }
    ,
  };

  const getAction = () => <Action type={ACTION_TYPES.GENERAL} data={ELIGIBILITY_DATA} />;

  const itemClick = (item) => {
    const isTreatment = item.trialType === 'drug';
    app.cache.itemData = {
      action: getAction(),
      button: <Button
        className="learn-more"
        title={t('search.trial_button_text')}
        action={() => {
          gotoQuestionnaire(searchParams, history);
        }}
      />,
    };
    history.push(`${ROUTES[!isTreatment ? PAGES.PUBLIC_TRIAL : PAGES.PUBLIC_TREATMENT]}/${item.nct_number}`);
  };

  const openFilters = () => {
    app.setPopup(POPUP_TYPES.PERSONALIZE, {
      trials,
      noLocation: true,
      noNew: true,
      filters,
      onSave: (f) => {
        app.setPopup(false);
        let isEmpty = true;
        for (const x in f) if (f[x].length) isEmpty = false;
        hasFilters = !isEmpty;
        setFilters(f);
        const results = getFilteredResults(resultsListController.getTab() === MODES.TRIALS ? unfiltered : unfilteredTreatments, f, true);
        setTrials(isEmpty ? (resultsListController.getTab() === MODES.TRIALS ? unfiltered : unfilteredTreatments) : results);
      },
    });
  };

  const hasResults = () => (resultsListController.getTab() === MODES.TREATMENTS ? (treatments && treatments.length) : (trials && trials.length));

  const renderResults = (renderItems, renderHeader)=>{
    // const items = resultsListController.getTab() === MODES.TRIALS ? trials : treatments;
    return (
      <>
        {renderHeader()}
        <div className="search-results-title">{t('general.trials')}</div>
        {
          trials.filter((a) => a.isSponsored).length ? (
            <>
              <div className="results-title">
                {t('search.sponsored_results')}
                {' '}
                <span onClick={openSponseredTrialInfoPopup}>?</span>
              </div>
              {renderItems(trials.filter((a) => a.isSponsored))}
              <div className="results-title">
                {t('search.all_clincal_trials')}
              </div>
              <div className={`search-results-wrapper ${expandTrials ? 'search-results-wrapper-expanded' : ''}`}>
                {renderItems(trials.filter((a) => !a.isSponsored))}
              </div>
              <div className="search-show-all-button">
                <LinkButton
                  title="Show all trials"
                  action={()=>{
                    setExpandTrials(!expandTrials);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="widget result-list">
              <div className={`search-results-wrapper ${expandTrials ? 'search-results-wrapper-expanded' : ''}`}>
                {renderItems(trials)}
              </div>
              <div className="search-show-all-button">
                <LinkButton
                  title="Show all trials"
                  action={()=>{
                    setExpandTrials(!expandTrials);
                  }}
                />
              </div>
            </div>
          )
        }
        <div className="search-results-title">{t('general.treatments')}</div>
        {
          treatments.filter((a) => a.isSponsored).length ? (
            <>
              <div className="results-title">
                {t('search.sponsored_results')}
                {' '}
                <span onClick={openSponseredTrialInfoPopup}>?</span>
              </div>
              {renderItems(treatments.filter((a) => a.isSponsored))}
              <div className="results-title">
                {t('search.all_clincal_trials')}
              </div>
              <div className={`search-results-wrapper ${expandTreatments ? 'search-results-wrapper-expanded' : ''}`}>
                {renderItems(treatments.filter((a) => !a.isSponsored))}
              </div>
              <div className="search-show-all-button">
                <LinkButton
                  title="Show all treatments"
                  action={()=>{
                    setExpandTreatments(!expandTreatments);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="widget result-list">
              <div className={`search-results-wrapper ${expandTreatments ? 'search-results-wrapper-expanded' : ''}`}>
                {renderItems(treatments)}
              </div>
              <div className="search-show-all-button">
                <LinkButton
                  title="Show all treatments"
                  action={()=>{
                    setExpandTreatments(!expandTreatments);
                  }}
                />
              </div>
            </div>
          )
        }
      </>
    );
  };

  // <div className="search-mobile-header-options">
  //   <div
  //     className="search-mobile-header-glass"
  //     onClick={() => {
  //       searchParamsApi.open();
  //     }}
  //   >
  //     <img alt="" src={GlassIcon} />
  //   </div>
  // </div>

  const customActions = () => {
    const form = {
      inputs: {
        location: {
          title: 'Trial Location',
          type: FORM_INPUT_TYPES.LOCATION,
          placeholder: 'Type location',
        },
        distance: {
          title: 'Distance from location',
          type: FORM_INPUT_TYPES.RADIO,
          options: [
            { title: '50 Miles', value: 50 },
            { title: '100 Miles', value: 100 },
            { title: '200 Miles', value: 200 },
            { title: 'Any', value: null },
          ],
        },
      },
      data: {
        location: searchParams?.country?.name,
        distance: searchParams?.distance,
      },
    };
    return (
      <div className={`results-header ${hasFilters && 'results-filters'}`}>
        <div className="results-header-button" onClick={openFilters}>
          <div className="results-header-icon">
            <img src={knobsIcon} alt="knobs" />
          </div>
          <div className="results-header-title">Filter</div>
        </div>
        { searchParams?.country?.name ? (
          <div
            className="results-header-button"
            onClick={() => {
              app.setPopup('general', {
                theme: 'middle',
                title: 'Change Location',
                content: (
                  <>
                    <Form form={form} />
                    <div className="height-20" />
                    <Button
                      title="Apply"
                      forwardIcon={ArrowRightIcon}
                      action={() => {
                        searchParamsApi.onChange({
                          location: { location: form.data.location, label: form.data.location },
                          distance: form.data.distance,
                        });
                        app.setPopup(false);
                      }}
                    />
                  </>
                ),
              });
            }}
          >
            <div className="results-header-icon">
              <img src={MapIcon} alt="knobs" />
            </div>
            <div className="results-header-title">{searchParams?.country?.name}</div>
          </div>
        ) : null}
        { hasResults() ? (
          <div
            className="results-header-button download-button"
            onClick={() => {
              // downloadPDF(resultsListController.getTab() === MODES.TREATMENTS ? treatments.map((a) => a._id).slice(0, 200) : trials.map((a) => a._id).slice(0, 200), MODES_TABS[resultsListController.getTab()]);
              downloadPDF([...trials.map((a) => a._id).slice(0, 100), ...treatments.map((a) => a._id).slice(0, 100)]);
            }}
          >
            <div className="results-header-icon">
              <img src={DownloadIcon} alt="knobs" />
            </div>
            <div className="results-header-title">Download</div>
          </div>
        ) : null}
      </div>
    );
  };

  const upload = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_FILES_CLICKED);
    app.setPopup(POPUP_TYPES.UPLOAD, {
      theme: 'middle',
      onBeforeIntake: ()=>{

      },
      onSuccess: async (user)=>{
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_SUCCESS);
        const user2params = await hcpApi.user2params(user);
        if (!user2params.success) {
          throw new Error('user2params failed');
        }
        const formData = params2form(user2params.data);
        searchParamsApi.set(formData);
        window._searchParams = user2params.data;
      },
    });
  };

  const NGS = ()=>{
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_CLICKED);
    app.setPopup(POPUP_TYPES.NGS, {
      theme: 'middle',
      onSuccess: (biomarkers) => {
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_SUCCESS, {
          biomarkers,
        });
        console.log('biomarkers found', biomarkers);
        searchParamsApi.onChange({
          biomarkers,
        });
        app.setPopup(false);
      },
    });
  };

  return (
    <div className={`search ${isNGSSource() ? 'search-ngs' : ''} ${app.hcpUser ? 'hcp-user' : ''}`}>
      {
        !params ? (
          <div className="page-loading">
            <Preloader isVisible />
          </div>
        ) : (
          <>
            <div className="upload-nav">
              <div className="info">
                <div className="result-list-mobile-header-options mobile-only">
                  <div
                    className="result-list-mobile-header-glass"
                    onClick={() => {
                      searchParamsApi.open();
                    }}
                  >
                    <img alt="" src={GlassIcon} />
                  </div>
                </div>
                <StarImg />
                <div className="desktop-only">{t('general.ai.assistant')}</div>
                <div className="buttons">
                  <Button
                    action={upload}
                    backgroundColor="#C77DFF"
                    title={t('hcp.bar_upload_files')}
                    icon={UploadIcon}
                    className="upload-files desktop-only"
                    width="210"
                  />
                  <Button
                    action={upload}
                    backgroundColor="#C77DFF"
                    title={t('hcp.bar_upload_files_mobile')}
                    className="upload-files mobile-only"
                    width="210"
                  />
                  <div className="or desktop-only">{t('general.or')}</div>
                  <Button
                    backgroundColor="#C77DFF"
                    title={t('hcp.bar_ngs_mobile')}
                    className="upload-files desktop-only"
                    icon={AnalyzeIcon}
                    width="210"
                    action={NGS}
                  />
                  <Button
                    backgroundColor="#C77DFF"
                    title={t('hcp.bar_ngs_mobile')}
                    className="upload-files mobile-only"
                    width="210"
                    action={NGS}
                  />
                </div>
              </div>
            </div>
            <div className="grid">
              <div className="results-container">
                { emptyResults ? <div className="search-empty">{t('search.no_results')}</div> : null}
                { showPreloader
                  ? <ResultsSkeleton />
                  : (
                    <ResultList
                      isSearchPage
                      trials={trials}
                      treatments={treatments}
                      customRenderer={renderResults}
                      customActions={customActions}
                      action={itemClick}
                      preventFiltering
                      controller={resultsListController}
                    />
                  )}
              </div>
              <div className="avoid-break search-actions">
                <div className="desktop-only">
                  <SearchParameters onSearch={onSearch} api={searchParamsApi} save={save} setShowPreloader={setShowPreloader} params={params} />
                  <div className="height-10" />
                  {/* <NGSWidget onSelect={(biomarkers) => {
              searchParamsApi.onChange({
                biomarkers,
              });
            }}
            /> */}
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

export default SearchTrials;
