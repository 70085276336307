import './Intercom.css';
// eslint-disable-next-line import/no-cycle
import app from 'new-ui/app';
import { DOM } from 'new-ui/functions';
import intercomIcon from 'new-ui/assets/icons/Illustration_closepath.svg';
import { INTERCOM_EVENTS, SURVEYS } from 'new-ui/constants';
import { getSurvey } from 'components/Surveys/api';
import { sortTreatmentByLatest } from 'new-ui/functions/sortTreatmentByLatest';
import moment from 'moment';
import { getIntercomMissingCriticalObject, getTopGroupsForIntercom, isEmpty } from './functions';
import { getDrugs } from '../QuestionRenderer/renderers/MultiPages/api';
import { deduplicateList } from '../../../functions/deduplicateList';
import getLatestDate from '../../../functions/getLatestDate';

// const INTERCOM_WRAPPER_CLASS = '.intercom-lightweight-app';

class IntercomClient {
  constructor() {
    const { intercomSettings } = window;
    this.settings = intercomSettings;
    this.appId = this.settings.app_id;
    this.isBooted = false;
    this.client = window.Intercom;
    this.setup();
  }

  async getIntercomUser({ user, userTrials } = {}) {
    const trials = userTrials || app?.trials || [];
    const newTrials = trials?.filter((trial) => trial.isNew);
    const nearestNewTrial = newTrials.sort(
      (a, b) => (a.nearest_facility_distance >= b.nearest_facility_distance ? 1 : -1),
    )[0];
    const topGroups = getTopGroupsForIntercom(trials);
    const sponsored = (trials && trials.length) ? trials.filter((t) => t.isSponsored).length : 0;

    let intercomUser = {
      app_id: this.appId,
      user_id: user?.condition_profile?.user_wix_id || user?.personal?.user_wix_id,
      created_at: user?.personal?.registered_at,
      app_last_updated: app?.user?.personal?.profile_updated_at
        ? Math.floor(new Date(app.user.personal.profile_updated_at).getTime() / 1000) : null,
      email: user?.info?.email,
      name: user?.info?.family_name,
      user_type: user?.info?.user_type,
      phone: user?.info?.phone,
      has_location: !isEmpty(user?.info?.country),
      num_targeted_therapy: trials.filter((a) => a.groups.includes('Targeted therapy')),
      zendesk_engagement_status: user?.personal?.user_engagement_status,
      profile_updated_at: Math.floor(new Date(user?.personal?.profile_updated_at
        ? user.personal.profile_updated_at : new Date()).getTime() / 1000),
      condition: window.t(`intercom.condition.title.${user?.personal?.condition}`),
      disease_status: user?.condition_profile?.disease_status,
      ecog: user?.condition_profile?.ecog || 0,
      ngs: user?.condition_profile?.ngs || 0,
      biomarkers: (user?.condition_profile?.biomarkers && user?.condition_profile?.biomarkers.length)
        ? user?.condition_profile?.biomarkers.length : 0,
      number_of_trials: trials.length,
      number_of_new_trials: newTrials.length,
      nearest_new_trial_name: nearestNewTrial && nearestNewTrial.title,
      nearest_new_trial_phase: nearestNewTrial && nearestNewTrial._phases.join(', '),
      nearest_new_trial_type: nearestNewTrial && nearestNewTrial.groups.join(', '),
      nearest_new_trial_location: nearestNewTrial && nearestNewTrial.closest_facility?.facility_name,
      provider: app?.provider?.code || 'Leal',
      Intervention_types: topGroups,
      continue_later: false,
      sponsored,
      file_types: null,
      last_document_date: null,
      last_upload_date: null,
    };
    const trialsForMissingCritical = userTrials || app?.trials || [];
    const missingCriticalObject = getIntercomMissingCriticalObject(user, trialsForMissingCritical);
    for (const x in missingCriticalObject) intercomUser[x] = missingCriticalObject[x];

    let uniqueAnswers = [];

    try {
      const surveyUserWixId = app.user.info.user_wix_id || intercomUser.user_id;
      const r = await getSurvey(SURVEYS.TREATMENT_EXPERIENCE, surveyUserWixId);
      if (r.data.answers) {
        const answers = r.data.answers || [];
        uniqueAnswers = [...new Set(answers.map((b) => b.surveyKey).filter((b) => b !== 'general'))];
      }
    } catch (e) { }
    const treatmentsLength = app?.user?.condition_profile?.treatments?.length
      || user?.condition_profile?.treatments?.length;
    const numTreatments = treatmentsLength || 0;
    let latestTreatment = '';

    if (numTreatments) {
      const drugs = await getDrugs();
      try {
        const [lastTreatment] = sortTreatmentByLatest(app?.user?.condition_profile?.treatments || []);
        latestTreatment = lastTreatment.drugs_received.map((drug)=>{
          if (drugs.data?.length) {
            const d = drugs.data.find((a)=>{
              return a.code === drug;
            });
            return d ? window.t(d.name) : drug;
          }
          return drug;
        }).sort()?.join(', ');
      } catch (e) { }
    }

    const treatmentData = {
      num_treatment_lines: numTreatments,
      treatment_experience_num_reported: uniqueAnswers.length,
      treatment_experience_num_unanswered: numTreatments - uniqueAnswers.length,
      latest_treatment: latestTreatment,
    };

    intercomUser = Object.assign(intercomUser, treatmentData);

    app.__lastIntercomUser = { ...intercomUser };
    return intercomUser;
  }

  setup() {
    setInterval(() => {
      const borderlessIframe = document.querySelector('.intercom-borderless-frame');
      const chatIframe = document.querySelector('iframe[name="intercom-chat-frame"]');
      const crmLauncher = document.getElementById('launcher');
      const webWidget = document.getElementById('webWidget');
      app.crmVisibility = crmLauncher && getComputedStyle(crmLauncher)?.getPropertyValue('visibility') === 'visible';
      app.webWidgetVisibility = webWidget && getComputedStyle(webWidget)?.getPropertyValue('visibility') === 'visible';
      const messengerIframe = document.querySelector('.intercom-messenger-frame');
      if (messengerIframe) {
        const computed = window.getComputedStyle(messengerIframe);
        if (computed.pointerEvents === 'all') {
          this.createOverlay();
          if (computed.bottom === '0px') {
            const $topButton = document.querySelector('.intercom-top-button');
            if ($topButton) return;
            this.createTopButton();
          } else {
            this.removeTopButton();
          }
          return;
        }
        this.removeTopButton();
      }
      this.removeOverlay();
      if (borderlessIframe || chatIframe) {
        this.createOverlay();
      }
      if (this.hidden) this.hideLauncher();
      else this.showLauncher();
    }, 500);
  }

  async bootPublic(data = {}, hideLauncher) {
    if (hideLauncher) this.hidden = true;
    this.createLauncher(true);
    if (!this.isBooted) {
      window.Intercom('boot', {
        app_id: this.appId,
        ...data,
      });
    }
    this.isBooted = true;
  }

  async boot(params = {}) {
    const { user = app?.user, data = {} } = params;
    this.createLauncher();
    if (!user) return;
    this.user = {
      ...await this.getIntercomUser({ user }),
      ...data,
    };

    const o = this.getStorageObject();

    if (o?.openOnBoot?.type === 'conversation') this.createOverlay();
    window.Intercom('onShow', this.onshow.bind(this));
    window.Intercom('onHide', this.onhide.bind(this));
    if (!this.isBooted) {
      window.Intercom('boot', this.user);
    } else {
      this.update();
    }
    this.isBooted = true;
  }

  async update({ user = app?.user, data = {}, userTrials } = {}) {
    if (!user) return;
    window.Intercom('update', {
      ...await this.getIntercomUser({ user, userTrials }),
      ...data,
    });
  }

  async updateUserAttributes(attributes = {}, userWixId = app?.user?.personal?.user_wix_id) {
    if (!userWixId) return;
    window.Intercom('update', {
      app_id: this.appId,
      user_id: userWixId,
      ...attributes,
    });
  }

  sendEvent(eventName, metadata = {}) {
    // console.log('sendEvent', eventName);
    window.Intercom('trackEvent', eventName, metadata);
  }

  shutdown() {
    window.Intercom('shutdown');
  }

  show() {
    window.Intercom('show');
  }

  hide() {
    window.Intercom('hide');
  }

  createOverlay() {
    this.removeOverlay();
    const $overlay = DOM('.intercom-overlay');
    document.body.appendChild($overlay);
  }

  removeOverlay() {
    const $overlay = document.querySelector('.intercom-overlay');
    if ($overlay) document.body.removeChild($overlay);
  }

  createTopButton() {
    const $topButton = DOM('.intercom-top-button', {
      onclick: () => {
        window.Intercom('hide');
      },
    });
    document.body.appendChild($topButton);
  }

  removeTopButton() {
    const $topButton = document.querySelector('.intercom-top-button');
    if ($topButton) document.body.removeChild($topButton);
  }

  removeLauncher() {
    const $b = document.querySelector('.intercom-button');
    if ($b && $b.parentElement) $b.parentElement.removeChild($b);
  }

  createLauncher(isPublic) {
    let n = 1;
    this.removeLauncher();
    const $button = DOM(`.intercom-button${isPublic ? '.intercom-public-button' : ''}`, {
      '/img': {
        src: intercomIcon,
      },
      '/.button-loading': {
        name: 'loader',
        style: {
          display: 'none',
        },
      },
      '/span': {
        innerHTML: isPublic ? 'I need help' : window.t('intercom.button.text'),
      },
      onclick: () => {
        window.Intercom('hide');
        const eventsObject = window.location.pathname === '/NGS' ? INTERCOM_EVENTS.NGS : INTERCOM_EVENTS.GENERAL;
        this.sendEvent(eventsObject[`LAUNCHER_CLICKED_${n}`]);
        n = n === 1 ? 2 : 1;
        document.querySelector('.intercom-button .button-loading').style.display = 'inline-block';
        setTimeout(() => {
          document.querySelector('.intercom-button .button-loading').style.display = 'none';
        }, 2000);
        setTimeout(() => {
          const $l = document.querySelector('.intercom-launcher');
          if ($l) {
            const event = new Event('mouseover');
            $l.dispatchEvent(event);
          }
        }, 2000);
      },
    });
    document.body.appendChild($button);
    if (this.hidden) this.hideLauncher();
  }

  showLauncher() {
    Array.from(document.querySelectorAll('.intercom-button')).forEach((a) => {
      a.style.display = 'flex';
    });
  }

  hideLauncher() {
    Array.from(document.querySelectorAll('.intercom-button')).forEach((a) => {
      a.style.display = 'none';
    });
  }

  onshow() {
    this.createOverlay();
  }

  onhide() {
    this.removeOverlay();
  }

  getStorageObject() {
    let o = null;
    for (let x = 0; x < localStorage.length; x++) {
      const n = localStorage.key(x);
      if (n.startsWith('intercom')) {
        o = JSON.parse(localStorage.getItem(n));
      }
    }
    return o;
  }

  logStorageObject() {
    console.log(this.getStorageObject());
  }
}

const Intercom = new IntercomClient();

window.__intercom = Intercom;

export default Intercom;

export const intercomSignupEvent = ()=>{
  setTimeout(() => { Intercom.sendEvent(INTERCOM_EVENTS.GENERAL.SIGNUP_15_SECONDS); }, 0);
  setTimeout(() => { Intercom.sendEvent(INTERCOM_EVENTS.GENERAL.SIGNUP_30_SECONDS); }, 15000);
};
