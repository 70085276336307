import React, { useEffect } from 'react';
import './Intake.css';
import PropTypes from 'prop-types';
import app from 'new-ui/app';
import arrowDownIcon from 'new-ui/assets/icons/arrow_down.svg';
import checkboxDoneIcon from 'new-ui/assets/icons/checkbox1.svg';
import { getProfileTitles } from 'new-ui/Popups/MedicalProfile/api';
// import { authenticationActions } from 'components/Authentication/actions';
import { useTranslation } from 'utils/modifiedTranslation';
import editIcon from 'new-ui/assets/icons/edit_2.svg';
import {
  capitalizeFirstLetter,
  // debounce,
  getBiomarkerTitle,
  hasGenderQuestion,
} from 'new-ui/functions';
import { render } from 'react-dom';
import { PAGES, ROUTES } from 'new-ui/constants';
import QuestionRenderer, { QUESTION_TYPES } from '../QuestionRenderer';
import {
  CUSTOM_INTAKE_QUESTIONS, INTAKE_ABOUT_QUESTION, INTAKE_QUESTIONS, INTAKE_SECTIONS,
} from './questions';

import { Accordion } from '../Accordion';

import { getPropertyTitle, ALL_MULTI_PAGES_QUESTIONS, MULTI_PAGES_QUESTIONS_TITLE } from '../QuestionRenderer/renderers/MultiPages';
import Preloader from '../Preloader';
import { conditionChecker, getQstack } from './functions';
import Button from '../Button';
import { GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS } from '../GoogleAnalytics/generalEvents';

const ABOUT_QUESTION_IDS = ['user_type', 'dob', 'gender', 'race'];

export const QUESTION_IDS = {
  TREATMENTS: 'treatments',
  PROCEDURES: 'procedures',
  BIOMARKERS: 'biomarkers',
};

export const isArrayQuestion = (q)=>{
  if (Object.values(QUESTION_IDS).includes(q.id)) return true;
};

export const CLIENT_IM_NOT_SURE = 'client_im_not_sure';
const notSureDictionary = JSON.parse(localStorage.getItem(CLIENT_IM_NOT_SURE)) || {};

window.clearImNotSure = ()=>{
  localStorage.removeItem(CLIENT_IM_NOT_SURE);
};

window.getImNotSure = ()=>{
  const notSureDictionary = JSON.parse(localStorage.getItem(CLIENT_IM_NOT_SURE)) || {};
  return notSureDictionary;
};

let allIntakeQuestions = [];
let titles;
// let saveIntake;

const intercomUpdated = false;

const isQuestionAnswered = (question, user = app.user) => {
  if (notSureDictionary[question.id]) return true;
  if (question.isQuestionAnswered) return question.isQuestionAnswered(user);
  if (question.id === 'user_type' && user?.info.user_type) return true;
  if (question.id === 'dob' && user?.personal.dob) return true;
  if (question.id === 'gender' && hasGenderQuestion() && user?.personal.gender) return true;
  if (question.id === 'race' && user?.condition_profile.race?.length) return true;
  const v = user.condition_profile[question.id];
  if (v === 0) return true;
  if (Array.isArray(v) && !v.length) return false;
  if (v === null || v === undefined) return false;
  return !!v;
};

const getConditionQuestions = (condition)=>{
  return {
    [INTAKE_SECTIONS.ABOUT_YOUR_CANCER]: INTAKE_QUESTIONS[INTAKE_SECTIONS.ABOUT_YOUR_CANCER][condition],
    [INTAKE_SECTIONS.ABOUT_YOUR_TREATMENT]: INTAKE_QUESTIONS[INTAKE_SECTIONS.ABOUT_YOUR_TREATMENT][condition],
    [INTAKE_SECTIONS.ABOUT_YOUR_HEALTH]: INTAKE_QUESTIONS[INTAKE_SECTIONS.ABOUT_YOUR_HEALTH][condition],
    [INTAKE_SECTIONS.ABOUT_YOU]: INTAKE_QUESTIONS[INTAKE_SECTIONS.ABOUT_YOU].all,
  };
};

export const getIntakeQuestions = (user = app.user, settings = {}) => {
  const {
    byId,
    byType,
    isShown,
    isHidden,
    isAnswered,
    isUnanswered,
    fromQid,
    customQuestions,
    appendQuestions,
    questionsData,
  } = settings;

  let questions = JSON.parse(JSON.stringify(app.questions));

  if (appendQuestions) questions = appendQuestions.concat(questions);

  const ALL_CONDITION_QUESTION = {
    [INTAKE_SECTIONS.ABOUT_YOUR_CANCER]: questions.filter((a)=>a.section === 'MY_CANCER').map((a)=>a.id),
    [INTAKE_SECTIONS.ABOUT_YOUR_TREATMENT]: questions.filter((a)=>a.section === 'TREATMENT').map((a)=>a.id),
    [INTAKE_SECTIONS.ABOUT_YOUR_HEALTH]: questions.filter((a)=>a.section === 'MY_HEALTH').map((a)=>a.id),
    [INTAKE_SECTIONS.ABOUT_YOU]: [],
  };

  let CONDITION_QUESTIONS = getConditionQuestions(user.personal.condition);

  let allQuestions = questions;
  let allIntakeQuestions = [];

  allQuestions = CUSTOM_INTAKE_QUESTIONS.concat(allQuestions);

  if (customQuestions) {
    allQuestions = allQuestions.filter((a)=>customQuestions.includes(a.id));
    CONDITION_QUESTIONS = ALL_CONDITION_QUESTION;
  }

  if (questionsData) {
    CONDITION_QUESTIONS = {};
    allQuestions = [];
    Object.keys(questionsData).forEach((type)=>{
      allQuestions = allQuestions.concat(questionsData[type]);
      CONDITION_QUESTIONS[type] = questionsData[type].map((a)=>a.id);
    });
  }

  const types = {};
  const ids = {};

  let sections = Object.values(INTAKE_SECTIONS);

  if (questionsData) sections = Object.keys(questionsData);

  sections.forEach((type) => {
    types[type] = [];
    const questions = allQuestions.filter((a) => CONDITION_QUESTIONS[type].includes(a.id));
    // console.log(allQuestions, type, questions, CONDITION_QUESTIONS, CONDITION_QUESTIONS[type]);
    CONDITION_QUESTIONS[type].forEach((a)=>questions.sort((b)=>{
      return b.id === a ? -1 : 1;
    }));
    questions.forEach((q)=>q.intakeType = type);
    allIntakeQuestions = allIntakeQuestions.concat(...questions.reverse());
  });

  if (fromQid) allIntakeQuestions = allIntakeQuestions.slice(allIntakeQuestions.findIndex((a)=>a.id === fromQid));

  allIntakeQuestions.forEach((q)=>{
    q.isAnswered = isQuestionAnswered(q, user);
    q.isShown = conditionChecker(q, user);
    if (q.id === 'insurances') q.isShown = true;
  });

  allIntakeQuestions = allIntakeQuestions.filter((q)=>{
    let f = true;
    if (isShown && !q.isShown) f = false;
    if (isHidden && q.isShown) f = false;
    if (isAnswered && !q.isAnswered) f = false;
    if (isUnanswered && q.isAnswered) f = false;
    return f;
  });

  allIntakeQuestions.forEach((q)=>{
    types[q.intakeType].push(q);
    ids[q.id] = q;
  });

  if (byId && byType) {
    return {
      ids,
      types,
    };
  }

  if (byId) return ids;
  if (byType) return types;

  return allIntakeQuestions;
};

window.__getIntakeQuestions = getIntakeQuestions;

export const getIntakeUnansweredNum = (user = app.user)=>{
  return getIntakeQuestions(user, {
    isShown: true,
    isUnanswered: true,
  }).length;
};

window.getIntakeUnansweredNum = getIntakeUnansweredNum;

let accordion = {};

const Intake = (props) => {
  const {
    user,
    showPreloader = true,
    onUpdate,
    gotoResultsAction,
    showOnlyUnanswered = false,
    showOnlyAnswered = false,
    preventNotSure,
    flatMode = false, // no top level type division
    customQuestions,
    appendQuestions,
    expandQuestions,
    customOnSelect,
    questionsData,
    sortSections,
  } = props;

  let {
    section,
    qid,
  } = props;

  if (qid === INTAKE_SECTIONS.ABOUT_YOU) {
    qid = null;
    section = INTAKE_SECTIONS.ABOUT_YOU;
  }

  const { t } = useTranslation();

  console.log('intake user', user);

  const { condition } = user.info;

  // console.log('intake condition', condition);

  useEffect(() => {
    window.__intakeUser = user;
    console.log('intake init', user);
    create();
    // eslint-disable-next-line
  }, []);

  const create = async () => {
    const titlesResult = await getProfileTitles(condition);
    titles = titlesResult.data;
    console.log('intake create', titles);

    app.questions = await app.getQuestions(user.personal.condition);

    const items = [];
    const allQuestions = getIntakeQuestions(user, {
      byType: true,
      customQuestions,
      appendQuestions,
      questionsData,
      isAnswered: showOnlyAnswered,
    });

    window.intakeQuestions = allQuestions;

    let sections = Object.values(INTAKE_SECTIONS);

    if (questionsData) sections = Object.keys(questionsData);

    if (sortSections) sections.sort((a)=>(sortSections.includes(a) ? -1 : 1));

    sections.forEach((type) => {
      const questions = allQuestions[type];
      if (!questions?.length) return;
      allIntakeQuestions = allIntakeQuestions.concat(...questions);

      if (!preventNotSure) {
        questions.forEach((q)=>{
          if (q.options && !q.options.find((a)=>a.value === 'not_sure')) {
            q.options.push({
              label: "I'm not sure",
              value: CLIENT_IM_NOT_SURE,
              isSingle: true,
            });
          }
        });
      }

      if (showOnlyUnanswered && !questions.find((a)=>!a.isAnswered && a.isShown)) return;

      const item = {
        isMain: true,
        type,
        title: t(`intake.section_title_${type}`),
        collapsed: section ? (section !== type) : true,
        className: 'intake-top-header',
        head: AccordionHeader,
      };

      items.push(item);

      const subitems = [];

      questions.forEach((q) => {
        let title = titles ? titles.en[q.id] : q.id.split('_').join(' ');
        if (q.intakeTitle) title = t(q.intakeTitle);
        if (q.id === 'biomarkers') title = t('intake.biomarkers_title');
        if (q.id === 'treatments') title = 'Treatments';
        if (q.id === 'procedures') title = 'Procedures';
        if (showOnlyUnanswered && q.isAnswered) return;
        const itemData = {
          name: q.id,
          q,
          title,
          collapsed: true,
          className: 'intake-item',
          head: QuestionHeader,
          body: QuestionBody,
        };
        subitems.push(itemData);
        item.items = subitems;
      });
    });

    accordion = new Accordion({
      $element: document.querySelector('.intake'),
      className: 'intake-accordion',
      items,
      onbeforerender: (accordion)=>{
        console.log('accordion onbefore render', accordion);
        // iterate all question, hide, and update counter

        const intakeQuestions = getIntakeQuestions(user, {
          byId: true,
          byType: true,
          isShown: true,
          customQuestions,
          appendQuestions,
          questionsData,
          // isUnanswered: showOnlyUnanswered,
        });

        // console.log('intakeQuestions', intakeQuestions);

        itemsIterator((item)=>{
          if (item.isMain) {
            item.counter = [
              intakeQuestions.types[item.type].filter((a)=>a.isAnswered).length,
              intakeQuestions.types[item.type].length,
            ];
          } else if (item.q) {
            item.hidden = !intakeQuestions.ids[item.q.id];
          }
        }, items);
      },
    });

    if (!items.length) {
      document.querySelector('.intake').innerHTML = '';
      render(
        <div className="intake-all-answered">
          <div className="intake-all-answered-title">
            {t('intake.all_questions_done')}
          </div>
          <div>
            <Button
              title={t('general.back_to_home')}
              action={()=>{
                if (gotoResultsAction) return gotoResultsAction();
                app.history.push(ROUTES[PAGES.RESULTS]);
              }}
            />
          </div>
        </div>,
        document.querySelector('.intake'),
      );
    }

    window._accordion = accordion;

    if (!section && !qid) showUnanswered();
    else if (qid) showQuestion(qid);

    if (expandQuestions) expandQuestions.forEach((q)=>showQuestion(q));
  };

  const itemsIterator = (callback, items = accordion.items)=>{
    if (!items) return;
    items.forEach((item)=>{
      const { items } = item;
      callback(item);
      if (!items) return;
      itemsIterator(callback, items);
    });
  };

  const showQuestion = (qid)=>{
    if (ABOUT_QUESTION_IDS.includes(qid)) qid = INTAKE_ABOUT_QUESTION.id;
    itemsIterator((item)=>{
      if (item.isMain) return;
      const question = item.q;
      if (question.id === qid) {
        item.show();
        item.parent.show();
      }
    });
  };

  const showUnanswered = (qid)=>{
    const questions = getIntakeQuestions(user, {
      isShown: true,
      isUnanswered: true,
      fromQid: qid,
      customQuestions,
      appendQuestions,
      questionsData,
    });
    if (questions.length) showQuestion(questions[0].id);
  };

  const getQuestionValue = (question) => {
    if (notSureDictionary[question.id]) return t('intake.im_not_sure');
    if (question.getQuestionValue) return question.getQuestionValue(user);
    const v = user.condition_profile[question.id];
    // console.log('getQuestionValue', question, user.condition_profile);
    const getValueLabel = (v) => {
      if (question.type === QUESTION_TYPES.NUMBER_INPUT) return v;
      if (question?.options?.length) {
        const option = question.options.filter((a) => a.value === String(v))[0];
        return t(option?.label);
      }
      return v;
    };
    if (v === null) return '-';
    if (ALL_MULTI_PAGES_QUESTIONS.includes(question.id)) {
      return v.map((item)=> getPropertyTitle(item, MULTI_PAGES_QUESTIONS_TITLE[question.id], question, t)).join(', ');
    }
    if (question.id === 'biomarkers' && v) return v.map((b) => getBiomarkerTitle(b)).join(', ');
    if (typeof v === 'object') return v.map(getValueLabel).join(', ');
    if (typeof v === 'string' || typeof v === 'number') return getValueLabel(v);
    return v;
  };

  const getQuestionTitle = (q) => {
    if (q.id === 'biomarkers') return window.t('intake.biomarkers_title');
    if (q.id === 'treatments') return 'Treatments';
    if (q.id === 'procedures') return 'Procedures';
    return t(q.title);
  };

  const questionToggle = (item)=>{
    itemsIterator((item)=>{ item.hide(); });
    item.show();
    item.parent.show();
  };

  const isLastItem = (item)=>{
    if (item.type === INTAKE_SECTIONS.ABOUT_YOU) {
      return true;
    }
    if (item.q) {
      const qs = getIntakeQuestions(user, {
        isShown: true,
        isUnanswered: showOnlyUnanswered,
        customQuestions,
        appendQuestions,
        questionsData,
      });
      if (!qs || !qs.length) return;
      if (qs[qs.length - 1].id === item.q.id) return true;
    }
  };

  const DoneButton = ()=>{
    return (
      <div className="intake-done-button">
        <Button
          title="Done"
          action={()=>{
            if (gotoResultsAction) return gotoResultsAction();
            app.history.push(ROUTES[PAGES.RESULTS]);
          }}
        />
      </div>
    );
  };

  const AccordionHeader = (item, $el) => {
    render(
      <div
        className="accordion-item-header"
        onClick={item.toggle}
      >
        <div className="flex">
          <div className="accordion-item-collapser">
            <img alt="collapser" src={arrowDownIcon} />
          </div>
          <div className="accordion-item-title">{item.title}</div>
        </div>
        <div>
          <div className="accordion-item-status">{`${item.counter[0]}/${item.counter[1]}`}</div>
        </div>
      </div>,
      $el,
    );
  };

  const QuestionHeader = (item, $el) => {
    // console.log('QuestionHeader item', item.q);
    const isAnswered = isQuestionAnswered(item.q, user);
    const toggle = ()=>{
      questionToggle(item);
    };
    render(
      isAnswered ? (
        <div className="intake-question-header intake-question-answered" onClick={toggle}>
          <div className="flex">
            <div className="intake-question-header-check">
              <img alt="check" src={checkboxDoneIcon} />
            </div>
            <div className="intake-question-header-title">
              <div className="intake-question-header-subtitle">{item.title}</div>
              <div className="intake-question-header-answer">
                {
                item?.q?.valueRenderer
                  ? capitalizeFirstLetter(item?.q?.valueRenderer(item.q))
                  : capitalizeFirstLetter(getQuestionValue(item.q))
                }
              </div>
            </div>
          </div>
          <div className="intake-question-header-collapser">
            <img alt="collapser" src={editIcon} />
          </div>
        </div>
      ) : (
        <div className="intake-question-header" onClick={toggle}>
          <div className="intake-question-header-title">
            {item.title}
          </div>
          <div className="intake-question-header-collapser">
            <img alt="collapser" src={arrowDownIcon} />
          </div>
        </div>
      ),
      $el,
    );
  };

  const QuestionBody = (item, $el) => {
    const { q } = item;
    // console.log('QuestionBody', q);
    render(
      (
        <div className="intake-question">
          <div className="intake-question-title">{ getQuestionTitle(q) }</div>
          <div className="intake-question-collapser">
            <img
              src={arrowDownIcon}
              alt="close"
              onClick={()=>{
                item.toggle();
              }}
            />
          </div>
          <QuestionRenderer
            q={q}
            user={user}
            initValue={notSureDictionary[q.id] ? CLIENT_IM_NOT_SURE : user?.condition_profile[q.id]}
            onselect={async (v, preventToggle, preventRender, removeFromNotSure = []) => {
              let newValue = v;

              delete notSureDictionary[q.id];
              removeFromNotSure.forEach((r)=>delete notSureDictionary[r]);
              if (newValue === CLIENT_IM_NOT_SURE || (Array.isArray(newValue) && newValue[0] === CLIENT_IM_NOT_SURE)) {
                newValue = null;
                notSureDictionary[q.id] = true;
              }
              localStorage.setItem(CLIENT_IM_NOT_SURE, JSON.stringify(notSureDictionary));

              if (q.setQuestionValue) q.setQuestionValue(newValue, user);
              else if (newValue === null && isArrayQuestion(q)) user.condition_profile[q.id] = [];
              else user.condition_profile[q.id] = newValue;

              if (customOnSelect) return customOnSelect(accordion, item, q, showUnanswered); // todo

              app.sendGoogleAnalyticsEvent(GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS.INTAKE_QUESTION_ANSWERED);

              app.user = user;

              if (!preventToggle) {
                item.$item.classList.add('intake-collapsed');
                setTimeout(()=>{
                  // comment
                  item.hide();
                  showUnanswered(q.id);
                  accordion.render();
                  item.$item.classList.remove('intake-collapsed');
                }, 1000);
              }

              const qstack = getQstack(user, app.questions);
              user.condition_profile.qstack = qstack;

              if (onUpdate) onUpdate(user);
              // saveIntake();

              if (!intercomUpdated) {
                app.intercom.update({
                  data: {
                    intake_engagement: true,
                  },
                });
              }

              const questions = getIntakeQuestions(user, {
                byType: true,
                isUnanswered: true,
                isShown: true,
                customQuestions,
                appendQuestions,
                questionsData,
              });
              const allQuestions = getIntakeQuestions(user, {
                byType: true,
                customQuestions,
                appendQuestions,
                questionsData,
              });

              console.log('intake onanswer: ', questions);

              const intercomUpdates = [];

              if (!questions[INTAKE_SECTIONS.ABOUT_YOUR_TREATMENT]?.length) {
                app.sendGoogleAnalyticsEvent(GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS.INTAKE_MEDICAL_DONE);
                intercomUpdates.push('intake_medical_completed');
              }

              let hasMedicalImNotSure = false;
              allQuestions[INTAKE_SECTIONS.ABOUT_YOUR_TREATMENT].forEach((q)=>{
                if (notSureDictionary[q.id]) hasMedicalImNotSure = true;
              });
              if (hasMedicalImNotSure) {
                intercomUpdates.push('intake_medical_im_not_sure');
              }

              if (!questions[INTAKE_SECTIONS.ABOUT_YOU]?.length) {
                app.sendGoogleAnalyticsEvent(GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS.INTAKE_ABOUT_DONE);
                intercomUpdates.push('intake_about_completed');
              }

              if (intercomUpdates.length) {
                const data = {};
                intercomUpdates.forEach((x)=>data[x] = true);
                app.intercom.update({
                  data,
                });
              }
            }}
          />
          { isLastItem(item) ? DoneButton() : null}
        </div>
      ),
      $el,
    );
  };

  return (
    <div className={`intake ${flatMode ? 'intake-flat-mode' : ''}`}>
      { showPreloader ? <Preloader inline height="150px" /> : null }
    </div>
  );
};

Intake.propTypes = {
  user: PropTypes.any,
  section: PropTypes.string,
  showPreloader: PropTypes.bool,
  flatMode: PropTypes.bool,
  onUpdate: PropTypes.func,
  gotoResultsAction: PropTypes.func,
  qid: PropTypes.string,
  showOnlyUnanswered: PropTypes.any,
  showOnlyAnswered: PropTypes.any,
  customQuestions: PropTypes.array,
  appendQuestions: PropTypes.array,
  expandQuestions: PropTypes.array,
  sortSections: PropTypes.array,
  customOnSelect: PropTypes.func,
  questionsData: PropTypes.any,
  customSections: PropTypes.any,
  preventNotSure: PropTypes.bool,
};

export default Intake;
