import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const CustomCheckbox = ({ checked, onChange, label }) => {
  return (
    <label className="custom-checkbox-v2">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className="custom-checkbox-box"></span>
    </label>
  );
};

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default CustomCheckbox;