import React, { useEffect, useState } from 'react';
import './style.css';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import { useTranslation } from 'utils/modifiedTranslation';

const WidgetSlider = ({ data, onClickDone, selectedOption, onFormChange }) => {
    const { t } = useTranslation();

    const { topSelectedDrugs, totalDrugs } = data;
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 4;

    const initialFormData = {
        selectedDrugs: [],
        cycles: [],
        timing: '',
        stop_reason: '',
        taking_state: '',
    };

    const [formData, setFormData] = useState(initialFormData);

    const updateFormData = (key, value) => {
        setFormData(prevData => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(() => {
        onFormChange(formData);
        // eslint-disable-next-line
    },[formData])

    const handleNext = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(prevStep => prevStep + 1);
        }
    };

    const handleDone = () => {
        console.log('PAYLOAD ',formData)
        onClickDone(formData);
    };

    const resetStep = () => {
        setCurrentStep(1);
        setFormData(initialFormData);
    };

    const renderComponent = (step) => {
        switch (step) {
            case 1:
            return (
                <StepOne 
                    topSelectedDrugs={topSelectedDrugs}
                    totalDrugs={totalDrugs}
                    onChangeTopSelected={(value) => { console.log('onChangeTopSelected: ',value) }} 
                    onChange={(value) => { console.log('StepOne onChange: ',value) }} 
                    onClickAdd={(value) => {
                        console.log('STEP ONE OUTPUT - SELECTED DRUGS ',value);
                        updateFormData('selectedDrugs', value);
                        handleNext();
                    }}
                />
            );
            case 2:
            return (
                <StepTwo 
                    options={data.cycles} 
                    onChange={(value) => { 
                        console.log('STEP TWO OUTPUT - CYCLES: ', value); 
                        updateFormData('cycles', value);
                        handleNext() 
                    }}
                />
            );
            case 3:
            return (
                <StepThree 
                    data={[
                        { title: t('treatment_widget.options.before_surgery'), value: 'before_surgery' },
                        { title: t('treatment_widget.options.after_surgery'), value: 'after_surgery' },
                        { title: t('treatment_widget.options.for_locally'), value: 'locally_advanced' }
                    ]}
                    onChange={
                        (value) => { 
                            console.log('STEP THREE OUTPUT - TIMING: ', value); 
                            updateFormData('timing', value);
                            handleNext() 
                        }} 
                    />
            );
            case 4:
            return (
                <StepFour 
                    selectedOption={selectedOption}
                    statuses={[
                        { title: t('treatment_widget.options.on_treatment'), value: 'taking' },
                        { title: t('treatment_widget.options.finish_treatment'),value: 'finished' },
                    ]}
                    reasons={data.stopReasons}
                    onChangeStatus={(value) => { 
                        console.log('STEP FOUR OUTPUT - STATUS: ', value); 
                        updateFormData('taking_state', value);
                    }} 
                    onChangeReason={(value) => {
                        console.log('STEP FOUR OUTPUT - REAON: ', value); 
                        updateFormData('stop_reason', value);
                    }}
                    onClickDone={handleDone}
                    onClickPrev={resetStep}
                />
            );
            default:
            return null;
        }
    };

    return (
        <div className='widget-slider'>
            <div className="content">
                {renderComponent(currentStep)}
            </div>

            <div className="bullets">
                {[...Array(totalSteps)].map((_, index) => (
                    <div key={index} className={`bullet ${index < currentStep ? 'full' : 'empty'}`}></div>
                ))}
            </div>
        </div>
    );
};

export default WidgetSlider;