/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
/* eslint-disable arrow-parens */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './AnswerPicker.css';

export const AnswerPicker = ({ options, title, onOptionSelected, isFullMode }) => {
  const [optionsSent, setOptionsSent] = useState(false);

  const handleOptionClick = async optionValue => {
    setOptionsSent(true);
    if (onOptionSelected) {
      onOptionSelected(optionValue);
    }
  };

  return (
    <div className={!isFullMode ? `${'chat-answer-picker'}` : `${'chat-answer-picker chat-answer-picker-full-screen'}`}>
      <div className="answer-picker-container">
        {!optionsSent ? (
          <>
            {title && <h2 className="chat-question-title">{title}</h2>}
            {options.map((option, index) => (
              <button key={index} className="option-button" onClick={() => handleOptionClick(option.value)}>
                {option.title}
              </button>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default AnswerPicker;