/* eslint-disable react/prop-types */
/* eslint-disable no-continue */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from 'react';
import './SearchParameters.css';

import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';

import app from 'new-ui/app';
import PropTypes from 'prop-types';
// import plusIcon from 'new-ui/assets/icons/plus.svg';
import addIcon from 'new-ui/assets/icons/add_round.svg';
// import minusIcon from 'new-ui/assets/icons/minus.svg';
// import LinkButton from 'new-ui/Components/LinkButton';
import SearchIcon from 'new-ui/assets/icons/search-status.svg';
// import arrowDown from 'new-ui/assets/icons/arrow_down.svg';
import circleAdd from 'new-ui/assets/icons/circle_add.svg';
import circleSubtract from 'new-ui/assets/icons/circle_subtract.svg';
import CheckIcon from 'new-ui/assets/icons/check_green.svg';
import { useTranslation } from 'utils/modifiedTranslation';
import { getSearchParamsApi } from 'new-ui/api';
import {
  DOM,
  createPopup,
  getLoggedUser,
} from 'new-ui/functions';
import Preloader from 'new-ui/Components/Preloader';
import Button from 'new-ui/Components/Button';
import {
  THEMES,
} from 'new-ui/constants';
import { getSearchApi, hcpApi } from '../api';
import { riskGroupIndications, riskGroupOptions } from './riskGroupOptions';
import { GA_HCP } from '../GA';

export const STORAGE_SEARCH_LOCATION = 'search_location';
export const STORAGE_SEARCH_INDICATION = 'search_indication';
export const STORAGE_SEARCH_BIOMARKERS = 'search_biomarkers';

let indications = [];
let indicationLabelsData = [];
let biomarkers = [];
let indicationLabelData = null;
let indication = null;
let location = null;
let typeData = null;
let subtypeOption = null;
let typeOption = null;

const DEFAULT_INDICATION = 'mpn'; // null = all

let diseaseStatusOptions = null;
let receivedTreatmentOptions = null;
let receivedAllogenicTransplantOptions = null;
let chromosomalAlterionsOptions = null;
let numTreatmentLinesOptions = null;

export const params2form = (params)=>{
  const _formType = {
    location: params.country || null,
    distance: params.distance || null,
    condition: params.condition || null,
    biomarkers: params.biomarkers || [],
    indicationType: params.diseaseType || null,
    diseaseStatus: params.diseaseStatus || null,
    indicationSubType: params.diseaseSubType?.length ? params.diseaseSubType[0] : null,
    receivedTreatment: params.receivedTreatment || null,
    chromosomalAlterions: params.chromosomalAlterions || null,
    receivedAllogenicTransplant: params.receivedAllogenicTransplant || null,
    numTreatmentLines: params.numTreatmentLines || null,
  };

  if (params.patientStage) _formType.stage = params.patientStage;
  if (params.patientStageNmibc) _formType.stage = params.patientStageNmibc;
  if (params.patientStageMibc) _formType.stage = params.patientStageMibc;
  if (params.patientStageMet) _formType.stage = params.patientStageMet;

  if (params.riskGroupApl) _formType.riskGroup = params.riskGroupApl;
  if (params.riskGroup) _formType.riskGroup = params.riskGroup;
  if (params.riskGroupIpi) _formType.riskGroup = params.riskGroupIpi;

  return _formType;
};

export const setSearchIndication = (condition = DEFAULT_INDICATION, indicationLabelsData)=>{
  return {};
};

window.setSearchIndication = setSearchIndication;

export const getEmptyParams = (condition = null, preventCache, props = {})=>{
  const params = localStorage.getItem('last_search_arguments');
  if (!preventCache && params) {
    try {
      const _params = JSON.parse(params);
      return _params;
    } catch (e) {}
  }
  const o = {
    country: null,
    distance: null,
    condition,
    biomarkers: [
      'none',
    ],
    diseaseType: null,
    diseaseStatus: null,
    receivedTreatment: null,
    chromosomalAlterions: null,
    receivedAllogenicTransplant: null,
    numTreatmentLines: null,
    diseaseSubType: null,
    patientStage: null,
    patientStageNmibc: null,
    patientStageMibc: null,
    patientStageMet: null,
    riskGroup: null,
    lang: 'en',
  };

  Object.keys(props).forEach((prop)=>o[prop] = props[prop]);

  console.log('object for params', o);

  return o;
};

const SearchParameters = (props) => {
  const {
    onSearch,
    api = {},
    save,
    setShowPreloader,
    params,
  } = props;
  const [form, setForm] = useState(app.cache.searchForm || null);
  const [collapsedParams, setCollapsedParams] = useState(true);
  // const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    diseaseStatusOptions = [
      { title: t('search.disease_status_new_disease'), value: 'new_disease' },
      { title: t('search.disease_status_refractory_disease'), value: 'refractory_disease' },
      { title: t('search.disease_status_recurrence'), value: 'recurrence' },
      { title: t('search.disease_status_partial_response_stable'), value: 'partial_response_stable' },
      { title: t('search.disease_status_remission'), value: 'remission' },
    ];
    receivedTreatmentOptions = [
      { title: t('search.yes'), value: 'yes' },
      { title: t('search.no'), value: 'no' },
    ];
    chromosomalAlterionsOptions = [
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.none'), value: 'none' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.17p_deletion'), value: '17p_deletion' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.1p_deletion'), value: '1p_deletion' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.1q_amplification'), value: '1q_amplification' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.4_14_translocation'), value: '4_14_translocation' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.11_14_translocation'), value: '11_14_translocation' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.16_14_translocation'), value: '16_14_translocation' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.20_14_translocation'), value: '20_14_translocation' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.trisomy'), value: 'trisomy' },
      { title: t('questionnaire.questions.mm.11_chromosomal_alterions.options.not_sure'), value: 'not_sure' },
    ];
    receivedAllogenicTransplantOptions = [
      { title: t('questionnaire.questions.mpn.14_received_allogenic_transplant.options.yes'), value: 'yes' },
      { title: t('questionnaire.questions.mpn.14_received_allogenic_transplant.options.no'), value: 'no' },
      { title: t('questionnaire.questions.mpn.14_received_allogenic_transplant.options.candidate'), value: 'candidate' },
      { title: t('questionnaire.questions.mpn.14_received_allogenic_transplant.options.scheduled'), value: 'scheduled' },
    ];
    numTreatmentLinesOptions = [
      { title: '0', value: '0' },
      { title: '1', value: '1' },
      { title: '2', value: '2' },
      { title: '3', value: '3' },
      { title: '4', value: '4' },
      { title: '5', value: '5' },
    ];
    const fetch = async () => {
      console.log('fetch', params);
      setShowPreloader(true);
      search(undefined, undefined, params);
      const searchParamsData = app?.cache?.searchParamsData || (await getSearchParamsApi());
      app.cache.searchParamsData = searchParamsData;
      if (searchParamsData) {
        indications = searchParamsData.indicationSearchData;
        indicationLabelsData = searchParamsData.indicationLabelsData;
        window.__indicationLabelsData = indicationLabelsData;
        window.__indications = indications;
        api.indicationLabelsData = indicationLabelsData;
        biomarkers = searchParamsData?.biomarkers.map((b) => ({ value: b.code, title: b.translations.en }));

        let data = getDefaultData();
        const _indication = params?.condition;
        console.log('_indication', _indication);
        if (params?.biomarkers) data.biomarkers = params.biomarkers;
        if (_indication) {
          const label = indicationLabelsData.filter((a) => a.isMain && a.value === _indication);
          if (label && label.length) {
            data.type = label[0].label;
            indication = indications.filter((p) => p.condition === _indication)[0];
          }
        }
        console.log('fetch::data', data);
        if (params) {
          const formData = params2form(params);
          console.log('formData', formData);
          data = Object.assign(data, formData);
        }
        setIndicationByData(data, { data: getDefaultData() });
        const f = updateForm(data);
        setForm(f);
        if (f.render) f.render();
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGeolocation = (onsuccess = () => {}, onerror = () => {}, onnogeolocation = () => {}) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude } = position.coords;
          const { longitude } = position.coords;
          const geocoder = new window.google.maps.Geocoder();
          const latlng = new window.google.maps.LatLng(latitude, longitude);
          geocoder.geocode({ latLng: latlng }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
              if (results[0]) {
                const address = results[0].formatted_address;
                location = {
                  label: address,
                  location: {
                    lat: latitude,
                    lng: longitude,
                  },
                };
                onsuccess(location);
              }
            }
          });
        },
        onerror,
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      onnogeolocation();
    }
  };

  const getStoredLocation = () => (localStorage.getItem(STORAGE_SEARCH_LOCATION) ? JSON.parse(localStorage.getItem(STORAGE_SEARCH_LOCATION)) : null);

  // setting up form fields in "advanced" fields area
  useEffect(() => {
    const $form = document.querySelector('form');
    if (!$form) return;
    setTimeout(() => {
      const $body = document.querySelector('.search-parameters-advanced-body');
      const $diseaseStatus = document.querySelector('.diseaseStatus');
      const $riskGroup = document.querySelector('.riskGroup');
      const $receivedTreatment = document.querySelector('.receivedTreatment');
      const $receivedAllogenicTransplant = document.querySelector('.receivedAllogenicTransplant');
      const $chromosomalAlterions = document.querySelector('.chromosomalAlterions');
      const $numTreatmentLines = document.querySelector('.numTreatmentLines');
      if ($diseaseStatus) $body.appendChild($diseaseStatus);
      if ($riskGroup) $body.appendChild($riskGroup);
      if ($receivedTreatment) $body.appendChild($receivedTreatment);
      if ($receivedAllogenicTransplant) $body.appendChild($receivedAllogenicTransplant);
      if ($chromosomalAlterions) $body.appendChild($chromosomalAlterions);
      if ($numTreatmentLines) $body.appendChild($numTreatmentLines);
      const $subtype = document.querySelector('.form-part.indicationSubType');
      const $biomarkers = document.querySelector('.form-part.biomarkers');
      if ($subtype && $biomarkers) $subtype.after($biomarkers);
    }, 1);
    if (form?.data?.location) {
      localStorage.setItem(STORAGE_SEARCH_LOCATION, JSON.stringify(form?.data?.location));
    }
    localStorage.setItem(STORAGE_SEARCH_INDICATION, indication ? indication.condition : '');
  }, [form]);

  const updateForm = (data_ = {}) => {
    const inputs = {
      type: {
        type: FORM_INPUT_TYPES.SELECT,
        title: t('search.indication'),
        placeholder: t('search.all_cancer_types'),
        autocomplete: true,
        options: [
          {
            title: t('search.all_cancer_types'),
            value: '',
          },
        ].concat(
          indicationLabelsData.map((ind) => ({
            title: ind.label,
            value: ind.label,
          })),
        ),
      },
    };

    let subtypeOptions = null;
    let typeOptions = null;
    let stageOptions = null;
    let riskOptions = null;

    if (indication) {
      indicationLabelData = indicationLabelsData.find((l) => l.label === typeData);
      typeOptions = [
        {
          title: '',
          value: '',
        },
      ].concat(
        indication.diseaseType.map((type) => ({
          title: t(`${type.label}`),
          value: type.value,
        })),
      );

      if (indication.patientStage && indication.patientStage.length) {
        stageOptions = [
          {
            title: '',
            value: '',
          },
        ].concat(
          indication.patientStage.map((stage) => ({
            title: t(`${stage.label}`),
            value: stage.value,
          })),
        );
      }

      if (indication.condition === 'bladder_2') {
        const stages = []
          .concat(indication.patientStageNmibc || [])
          .concat(indication.patientStageMibc || [])
          .concat(indication.patientStageMet || []);
        stageOptions = [
          {
            title: '',
            value: '',
          },
        ].concat(
          stages.map((stage) => ({
            title: t(`${stage.label}`),
            value: stage.value,
          })),
        );
      }

      // type auto select
      if (!form?.data?.indicationType && typeOption == null) {
        typeOption = indicationLabelData?.autoSelectType || null;
      } else {
        typeOption = '';
      }
      const indicationType = form?.data?.indicationType || typeOption || '';
      if (indicationLabelData?.subType && indication[indicationLabelData.subType]?.length) {
        subtypeOptions = [
          {
            title: '',
            value: 'empty',
          },
        ].concat(
          indication[indicationLabelData.subType].map((subtupe) => ({
            title: t(`${subtupe.label}`),
            value: subtupe.value,
          })),
        );
      } else {
        subtypeOptions = null;
        subtypeOption = null;
        if (form?.data) form.data.indicationSubType = null;
      }

      // subtype auto select
      if (!form?.data?.indicationSubType && indicationType === indicationLabelData?.autoSelectType && !subtypeOption) {
        subtypeOption = indicationLabelData?.autoSelectSubType || null;
      } else {
        subtypeOption = null;
      }
    }

    inputs.indicationType = {
      type: FORM_INPUT_TYPES.SELECT,
      title: t('search.type'),
      placeholder: t('search.type'),
      options: typeOptions || [],
      disabled: !typeOptions,
    };

    inputs.indicationSubType = {
      type: FORM_INPUT_TYPES.SELECT,
      title: t('search.subtype'),
      placeholder: t('search.subtype'),
      options: subtypeOptions || [],
      disabled: !subtypeOptions,
    };

    if (stageOptions) {
      inputs.stage = {
        type: FORM_INPUT_TYPES.SELECT,
        title: t('search.stage'),
        placeholder: t('search.stage'),
        options: stageOptions || [],
        disabled: !stageOptions,
      };
    }

    if (indication && riskGroupIndications.indexOf(indication.condition) !== -1) {
      let prop = indication?.condition;
      if (indication?.condition === 'aml') {
        if (form?.data?.indicationType === 'apl') prop = 'aml_apl';
        else if (form?.data?.indicationType === 'aml') prop = 'aml';
        else prop = null;
      }
      if (prop) {
        riskOptions = riskGroupOptions[prop].map((r) => ({
          title: t(r.label),
          value: r.value,
        }));

        inputs.riskGroup = {
          type: FORM_INPUT_TYPES.SELECT,
          title: t('search.risk_group'),
          placeholder: t('search.risk_group'),
          options: riskOptions || [],
        };
      }
    }

    inputs.diseaseStatus = {
      type: FORM_INPUT_TYPES.SELECT,
      // title: t('search.disease_status'),
      placeholder: t('search.disease_status'),
      options: diseaseStatusOptions || [],
      disabled: !indication,
    };

    if (indication && indication.condition === 'mm') {
      inputs.chromosomalAlterions = {
        type: FORM_INPUT_TYPES.SELECT,
        // title: t('search.chromosomal_alterions'),
        placeholder: t('search.chromosomal_alterions'),
        options: chromosomalAlterionsOptions || [],
        disabled: !indication,
      };
    }

    if (indication && indication.condition === 'mpn' && form?.data?.indicationType === 'myelofibrosis') {
      inputs.receivedAllogenicTransplant = {
        type: FORM_INPUT_TYPES.SELECT,
        // title: t('search.received_allogenic_transplant'),
        placeholder: t('search.received_allogenic_transplant'),
        options: receivedAllogenicTransplantOptions || [],
      };
    }

    inputs.numTreatmentLines = {
      type: FORM_INPUT_TYPES.SELECT,
      // title: t('search.num_treatment_lines'),
      placeholder: t('search.num_treatment_lines'),
      options: numTreatmentLinesOptions || [],
    };

    inputs.receivedTreatment = {
      type: FORM_INPUT_TYPES.SELECT,
      // title: t('search.received_treatment'),
      placeholder: t('search.received_treatment'),
      options: receivedTreatmentOptions || [],
      disabled: !indication,
    };

    inputs.biomarkers = {
      type: FORM_INPUT_TYPES.SELECT,
      title: t('search.biomarkers'),
      placeholder: t('search.biomarkers'),
      multi: true,
      autocomplete: true,
      autocompletePlaceholder: t('search.start_typing_biomarkers'),
      onClear: ()=>{
        console.log('onClear');
        localStorage.removeItem(STORAGE_SEARCH_BIOMARKERS);
      },
      nullValue: 'none',
      options: [
        {
          title: t('search.clear_biomarkers'),
          value: '',
          unfiltered: true,
          className: 'clear_btn',
        },
      ].concat(biomarkers),
      isTags: true,
    };

    inputs.location = {
      type: FORM_INPUT_TYPES.LOCATION,
      title: t('search.location'),
    };

    inputs.distance = {
      hidden: true,
      type: FORM_INPUT_TYPES.INPUT,
      title: 'Distance',
    };

    const data = {
      type: indication ? typeData : '',
      indicationType: form?.data?.indicationType || typeOption || '',
      indicationSubType: form?.data?.indicationSubType || subtypeOption || '',
      stage: form?.data?.stage || '',
      diseaseStatus: form?.data.diseaseStatus || '',
      receivedTreatment: form?.data.receivedTreatment || '',
      receivedAllogenicTransplant: form?.data.receivedAllogenicTransplant || '',
      chromosomalAlterions: form?.data.chromosomalAlterions || '',
      numTreatmentLines: form?.data.numTreatmentLines || '',
      biomarkers: form?.data?.biomarkers?.length
        ? form.data.biomarkers.length > 1 && form.data.biomarkers.includes('none')
          ? form.data.biomarkers.filter((b) => b !== 'none')
          : form.data.biomarkers
        : ['none'],
      location: form?.data?.location || '',
      distance: form?.data?.distance || '',
      riskGroup: form?.data?.riskGroup || '',
      ...data_,
    };

    // console.log('update data', data);

    const newForm = {
      inputs,
      data,
    };
    setForm(newForm);

    return newForm;
  };

  const getDefaultData = () => ({
    location: null,
  });

  const getSearch = async (searchArguments, f, clearIndication, preventUpdate)=>{
    localStorage.setItem('last_search_arguments', JSON.stringify(searchArguments));
    if (searchArguments.condition && app.hcpUser) { // update hcp user
      const userResponse = await hcpApi.params2user(searchArguments); // patient params
      console.log('onSearch params2user', userResponse);
      if (!userResponse.success) console.log('onSearch params2user error', userResponse);
      const _user = userResponse.data;
      const o = JSON.parse(JSON.stringify(app.hcpUser));
      for (const x in _user.condition_profile) {
        o.condition_profile[x] = _user.condition_profile[x];
      }
      if (_user?.info?.country) o.info.country = _user.info.country;
      console.log('updateUser data', o); // todo update in patient list
      const updatedUser = await hcpApi.updateUser(o);
      app.hcpUser = o;
      console.log('patient updated', updatedUser); // todo update in patient list
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PROFILE_UPDATED);
      app.setQuickNote({
        title: t('hcp.patient_updated_success'),
      });
    }
    window._searchParams = searchArguments;
    getSearchApi(searchArguments, (r) => {
      if (!r) return;
      setShowPreloader(false);
      const trials = r?.trials?.result;
      const treatments = r?.trials?.treatments;
      app.treatmentsCategories = r?.trials?.treatmentCategoryTree?.map((treatment) => {
        const treatments = treatment.nct_numbers
          .map((id) => r?.trials.treatments
            .find(({ nct_number }) => nct_number === id)).filter((i) => i);
        return {
          ...treatment,
          treatments,
        };
      });
      app.cache.searchForm = form;
      if (searchArguments?.country?.location) {
        localStorage.setItem('searchCountryLocation', JSON.stringify(searchArguments?.country?.location));
      } else {
        localStorage.removeItem('searchCountryLocation');
      }
      if (clearIndication) searchArguments.condition = null;
      onSearch(trials || [], treatments || [], searchArguments, preventUpdate);
      if (!f) { // after first results only
        const storedLocation = getStoredLocation();
        if (storedLocation) return;
        setTimeout(() => {
          getGeolocation(
            (location) => {
              const _f = updateForm({
                location,
                // biomarkers,
              });
              search(null, _f);
            },
          );
        }, 3000);
      }
    });
  };

  const search = async (arg, f, params) => {
    setShowPreloader(true);
    if (params) {
      getSearch(params, undefined, undefined, true);
      return;
    }
    const _form = f || form || {
      data: getDefaultData(),
    };
    const formBiomarkers = _form.data?.biomarkers;

    const stages = {
      patientStage: _form.data.stage || null,
      patientStageNmibc: _form.data.stage || null,
      patientStageMibc: _form.data.stage || null,
      patientStageMet: _form.data.stage || null,
    };

    const riskGroup = {};

    const condition = _form.data.type ? indicationLabelsData.find((i) => i.label === _form.data.type)?.value : null;

    let biomarkers = ['none'];
    if (formBiomarkers && formBiomarkers.length) biomarkers = formBiomarkers;
    if (biomarkers.includes('unknown')) biomarkers = null;

    if (['aml', 'apl'].includes(condition)) {
      riskGroup.riskGroupApl = _form.data.riskGroup || null;
    } else if (['mds', 'mpn', 'aml'].includes(condition)) {
      riskGroup.riskGroup = _form.data.riskGroup || null;
    } else if (['cll'].includes(condition)) {
      riskGroup.riskGroupIpi = _form.data.riskGroup || null;
    }

    const searchArguments = {
      country: _form.data.location
        ? {
          location: _form?.data?.location?.location,
          name: _form?.data?.location?.label,
        }
        : null,
      distance: _form.data.distance || null,
      condition,
      biomarkers,
      diseaseType: _form.data.indicationType || null,
      diseaseStatus: _form.data.diseaseStatus || null,
      receivedTreatment: _form.data.receivedTreatment || null,
      chromosomalAlterions: _form.data.chromosomalAlterions || null,
      receivedAllogenicTransplant: _form.data.receivedAllogenicTransplant || null,
      numTreatmentLines: _form.data.numTreatmentLines || null,
      diseaseSubType:
        _form.data.indicationSubType && _form.data.indicationSubType !== 'empty'
          ? {
            subtype: indicationLabelData.subType,
            value: _form.data.indicationSubType,
          }
          : null,
      // patientSubStage: null,
      ...stages,
      ...riskGroup,
      lang: localStorage.lang || 'en',
    };
    let userId;
    try {
      userId = JSON.parse(localStorage.getItem('user')).user_wix_id;
    } catch (e) {}
    if (userId) {
      searchArguments.userId = userId;
    }

    // if (
    //   !searchArguments.condition
    //   && searchArguments.biomarkers?.length === 1
    //   && searchArguments.biomarkers[0] === 'none'
    //   && !searchArguments.diseaseType
    //   && !searchArguments.diseaseSubType
    //   && !searchArguments.patientStage
    //   && !searchArguments.patientStageNmibc
    //   && !searchArguments.patientStageMibc
    //   && !searchArguments.patientStageMet
    // ) {
    //   searchArguments.isDefault = true;
    // }
    getSearch(searchArguments, _form);
  };

  api.updateForm = updateForm;

  const setIndication = (condition)=>{
    const selectedIndication = indications.find((p) => p.condition === condition);
    const selectedIndicationByLabel = indicationLabelsData.find((p) => p.value === condition);
    form.data.indicationType = null;
    form.data.indicationSubType = null;
    form.data.stage = '';
    form.data.riskGroup = '';
    form.data.biomarkers = [];
    subtypeOption = null;
    typeOption = null;
    typeData = selectedIndicationByLabel?.label || null;
    indication = selectedIndication || null;
    const updatedForm = updateForm();
    search(null, updatedForm);
  };

  api.setIndication = setIndication;

  window.__setIndication = setIndication;

  const setIndicationByData = (data, form) => {
    window.___spForm = form;
    const { type } = data;
    const selectedIndicationByLabel = indicationLabelsData.filter((p) => p.label === type);
    const selectedIndication = indications.filter((p) => p.condition === selectedIndicationByLabel[0]?.value)[0];
    if (indication !== selectedIndication || type !== typeData) {
      form.data.indicationType = null;
      form.data.indicationSubType = null;
      form.data.stage = '';
      form.data.riskGroup = '';
      subtypeOption = null;
      typeOption = null;
    }
    typeData = type;
    indication = selectedIndication;
  };

  const onChange = async (data) => {
    setIndicationByData(data, form);
    const updatedForm = updateForm();
    await search(null, updatedForm);
  };

  api.onChange = async (data = {}) => {
    if (!form) return;
    localStorage.setItem(STORAGE_SEARCH_BIOMARKERS, data?.biomarkers ? JSON.stringify(data.biomarkers) : '[]');
    setIndicationByData(form.data, form);
    const updatedForm = updateForm(data);
    await search(null, updatedForm);
  };

  api.set = async (form)=>{
    if (form.condition) {
      const selectedIndication = indications.find((p) => p.condition === form.condition);
      const selectedIndicationByLabel = indicationLabelsData.find((p) => p.value === form.condition);
      typeData = selectedIndicationByLabel?.label || null;
      indication = selectedIndication || null;
    }
    const f = updateForm(form);
    setForm(f);
    if (f.render) f.render();
  };

  window._api = api;

  const openParamsPopup = () => {
    const $form = document.querySelector('.search-parameters-form');
    const classes = ['params-popup'];
    if (app.hcpUser) classes.push('hcp-user');
    const $popup = createPopup({
      className: classes,
      onbeforeclose: () => {
        document.querySelector('.search-parameters-form-container').appendChild($form);
      },
    });
    $popup.$body.appendChild($form);
    $popup.$body.appendChild(DOM('.search-params-button', {
      onclick: () => {
        document.querySelector('.search-parameters-form-container').appendChild($form);
        $popup.remove();
        search();
      },
      '/img': {
        src: SearchIcon,
      },
      '/.search-params-button-inner': {
        innerHTML: 'Search',
      },
    }));
  };

  api.open = openParamsPopup;

  return (
    <div className="search-parameters">
      {form ? (
        <div className="search-parameters-form-container">
          {
            app.hcpUser ? (
              <div className="search-parameters-patient">
                { app.hcpUser.info.family_name || 'Anon. Patient' }
              </div>
            ) : null
          }
          <div className="search-parameters-header">
            <div className="search-parameters-header-title">{t('search.box_title')}</div>
            {/* <img alt="collapse" src={arrowDown} /> */}
          </div>
          <div className="search-parameters-form-container">
            <div className="search-parameters-form">
              <Form form={form} onChange={onChange} className={indication ? 'form-indication' : ''} />
              <div className={`search-parameters-advanced ${collapsedParams ? 'search-parameters-advanced-collapsed' : ''} ${indication ? 'form-indication' : ''}`}>
                <div className="search-parameters-advanced-head" onClick={() => { setCollapsedParams(!collapsedParams); }}>
                  <img alt="collapse" src={collapsedParams ? circleAdd : circleSubtract} />
                  {' '}
                  {t('search.more_options')}
                </div>
                <div className="search-parameters-advanced-body-container">
                  <div className="search-parameters-advanced-body" />
                  { getLoggedUser() ? null
                    : (
                      <div className="search-parameters-advanced-actions">
                        <Button theme={THEMES.LIGHT} icon={CheckIcon} action={save} title="Save profile" />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="search-parameters-mobile-open" onClick={openParamsPopup}>
            <img alt="props" src={addIcon} />
          </div>
        </div>
      ) : <div className="params-preloader"><Preloader isVisible zIndex={10} /></div>}
    </div>
  );
};

SearchParameters.propTypes = {
  onSearch: PropTypes.func,
  save: PropTypes.func,
  api: PropTypes.object,
  patient: PropTypes.object,
  setShowPreloader: PropTypes.func,
};

export default SearchParameters;
