import React, { useEffect, useState } from 'react';
import './style.css';
import HeartIcon from 'new-ui/assets/icons/heart.svg';
import { useTranslation } from 'utils/modifiedTranslation';
import { getSurvey } from 'components/Surveys/api';
import { SURVEYS } from 'new-ui/constants';
import app from 'new-ui/app';
import TreatmentIntensity from './TreatmentIntensityV2';
import TreatmentSideEffects from './TreatmentSideEffects';
import AssistanceButton from '../AssistanceButton';
import WidgetBtn from '../Button';
import ArrowButton from '../ArrowButton';
import { mockSideEffects, mockIntensities } from './mockData';
import { FEED_GA } from '..';

const labels = {
  not_aggressive: 'Not Intense',
  somewhat_aggresive: 'Somewhat Intense',
  very_aggressive: 'Very Intense',
};

const ExperienceWidget = ({
  onClickAddMyExperance, onChangeSelectedDrug, selectedDrug, changesNeed, reviewedDrugs,
}) => {
  const { t } = useTranslation();
  const [currentDrugIndex, setCurrentDrugIndex] = useState(0);

  const [currentDrug, setCurrentDrug] = useState(null);
  const [sideEffects, setSideEffects] = useState([]);
  // const [intensities, setIntensities] = useState([]);
  const [intensitiesMap, setIntensitiesMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [drugs, setDrugs] = useState([]);
  const [patientsCount, setPatientsCount] = useState(0);

  const fetch = async () => {
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.experienceViewed, {
      widget: 'treatment experience',
    });
    setIsLoading(true);
    const experiences = await getSurvey(SURVEYS.TREATMENT_EXPERIENCE, app.user.info.user_wix_id);
    const { drugs } = experiences.data.data;
    const drug = (selectedDrug ? drugs.find((d) => d.name === selectedDrug.name) : selectedDrug) || drugs[drugs.length - 1];
    setCurrentDrug(drug);
    arrangeData(drug);
    setPatientsCount(experiences.data.data.patients_with_same_indication);
    setDrugs(experiences.data.data.drugs);
    setIsLoading(false);
  };
  useEffect(()=>{
    fetch();
    // eslint-disable-next-line
    },[])

  useEffect(() => {
    if (changesNeed.experienceWidget) {
      fetch();
    }
    // eslint-disable-next-line
    }, [changesNeed.experienceWidget]);

  useEffect(() => {
    const drug = drugs[currentDrugIndex];
    setCurrentDrug(drug);
    onChangeSelectedDrug(drug);
    arrangeData(drug);
    // eslint-disable-next-line
    },[currentDrugIndex])

  const convertArrayToObject = (arr) => {
    return arr.reduce((acc, item) => {
      const { value, ...rest } = item;
      acc[value] = { ...rest };
      return acc;
    }, {});
  };

  const getIntensities = () => {
    const options = [];
    ['not_aggressive', 'somewhat_aggresive', 'very_aggressive'].forEach((intensity) => {
      options.push({
        percent: 0,
        value: intensity,
        title: labels[intensity],
        ...intensitiesMap[intensity],
      });
    });
    return options;
  };

  const onNext = () => {
    setCurrentDrugIndex((prevIndex) => (prevIndex + 1) % drugs.length);
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.experienceInteracted, {
      widget: 'treatment experience',
    });
  };

  const onPrev = () => {
    setCurrentDrugIndex((prevIndex) => (prevIndex - 1 + drugs.length) % drugs.length);
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.experienceInteracted, {
      widget: 'treatment experience',
    });
  };

  const onBtnClick = () => {
    onClickAddMyExperance();
    onChangeSelectedDrug(currentDrug);
    app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.experienceInteracted, {
      widget: 'treatment experience',
    });
  };

  const getDrugName = () => {
    let names = '';
    if (currentDrug) {
      names = currentDrug.name.split('+').map((name) => t(`questionnaire.questions.drugs_received.${name}`));
      names = names.join(' + ');
    }
    return names;
  };

  useEffect(() => {
    const drug = selectedDrug || drugs[currentDrugIndex];
    setCurrentDrug(drug);
    arrangeData(drug);
    // eslint-disable-next-line
    },[selectedDrug])

  const arrangeData = (drug) => {
    if (drug) {
      const sideEffects = drug?.answerStatistics.filter((el) => (el.answerKey === 'side_effects' && el.value !== 'no_sideeffects'));
      const intensities = drug?.answerStatistics.filter((el) => (el.answerKey === 'aggression' && ['somewhat_aggresive', 'not_aggressive', 'very_aggressive'].includes(el.value)));

      if (!sideEffects.length) {
        sideEffects.push(...mockSideEffects);
      }
      if (!intensities.length) {
        intensities.push(...mockIntensities);
      }

      const intensitiesMap = convertArrayToObject(intensities);

      setIntensitiesMap(intensitiesMap);
      setSideEffects(sideEffects);
      // setIntensities(intensities);
    }
  };

  return (
    <>
      {(!isLoading && currentDrug) && (
        <div className="experience-widget">
          <div className={`widget-header ${drugs.length > 0 ? 'arrows' : ''}`}>
            <ArrowButton direction="left" onClick={onPrev} />
            <ArrowButton direction="right" onClick={onNext} />
            <div className="details">
              <div className="label">{getDrugName()}</div>
            </div>
          </div>
          <div className="widget-body">
            {drugs.length > 1 && (
              <>
                <ArrowButton direction="left" onClick={onPrev} />
                <ArrowButton direction="right" onClick={onNext} />
              </>
            )}
            <h2 className="title">{t('statistic_widget.title.main')}</h2>
            <h3 className="sub-title">{t('statistic_widget.title.graph')}</h3>

            <TreatmentIntensity options={getIntensities()} />
            <TreatmentSideEffects sideEffects={sideEffects} />

            {!reviewedDrugs[currentDrug?.name] && (
              <div className="actions">
                <h2 className="title">
                  {`${t('experience_widget.help_patients.p1')} ${patientsCount} ${t('experience_widget.help_patients.p2')}`}
                </h2>
                <WidgetBtn title="Add your experience" onClick={onBtnClick} />
              </div>
            )}
            {reviewedDrugs[currentDrug?.name] && (
              <div className="feedback">
                <img src={HeartIcon} alt="icon" />
                <span>
                  {`${t('statistic_widget.title.feedback.p1')} ${patientsCount} ${t('statistic_widget.title.feedback.p2')}`}
                </span>
              </div>
            )}
          </div>
          <div className="widget-footer">
            <AssistanceButton onClick={() => {}} />
          </div>
        </div>
      )}
    </>
  );
};

export default ExperienceWidget;
