import React from 'react';

import './Playground.css';
import IntakeAboutYou from 'new-ui/Components/QuestionRenderer/renderers/IntakeAboutYou';
import app from 'new-ui/app';

const Playground = () => {
  app.questions = [];
  return (
    <IntakeAboutYou user={{
      condition_profile: {},
      info: {},
      personal: {},
    }}
    />
  );
};

export default Playground;
